import catPhoto from '../images/cat1.jpg'
import React from 'react'
import { useMediaQuery } from 'react-responsive'

const About = () => {

    const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' })

    const content = (
        <>
            <meta charSet="utf-8" />
            <meta name="viewport"
                content="width=device-width, initial-scale=1.0, shrink-to-fit=no" />
            <div className='about-bg'>

                {/* Display for Desktop */}
                {!isSmallScreen && <div className="about container">
                    <div className="about row">
                        <div className="about col">
                            <h4><span className="about cursive">Hello! &nbsp;</span></h4>
                            <div className="about aText">
                                <p>I'm Logan and I love exploring new technologies and learning how things work. Some of my future projects include Machine Learning, Containerization of Applications and  Snowflake</p>
                                <p>Thank You to  &nbsp;&nbsp;
                                    <i><a href="https://github.com/AllusiveBox/allusivebox" target='_blank' rel="noreferrer">AllusiveBox</a></i> &nbsp;&nbsp;
                                    who was a great resource.
                            <br /><br />
                            Eventually this fine feline below will be replaced with the steps, tools, and libraries used to help create this website.
                            <br />
                                </p>
                            </div>
                        </div>
                        <div className="about col">
                            <div className="about aPic">
                                <img src={catPhoto} className="about img-fluid rounded" alt="self" />
                            </div>
                        </div>
                    </div>
                </div>}

                {/* Display for mobile phones */}
                {isSmallScreen && <div className="about container">
                    <h4><span className="about cursive">Hello! &nbsp;</span></h4>
                    <div className="about aText">
                        <p>I'm Logan and I love exploring new technologies and learning how things work. Some of my future projects include Machine Learning, Containerization of Applications and  Snowflake</p>
                        <p>Thank You to  &nbsp;&nbsp;
                            <i><a href="https://github.com/AllusiveBox/allusivebox" target='_blank' rel="noreferrer">AllusiveBox</a></i> &nbsp;&nbsp;
                            who was a great resource.
                            <br /><br />
                            Eventually this fine feline below will be replaced with the steps, tools, and libraries used to help create this website.
                            <br />
                        </p>
                    </div>
                    <img src={catPhoto} alt='catPhoto' className='img-fluid rounded' />
                </div>}
            </div>
        </>
    )
    return content;
}
export default About;
