import { useState } from "react"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactForm = () => {

    // Set default values
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [message, setMessage] = useState('');

    // Submit Form
    const formSubmit = async (e) => {
        e.preventDefault();

        // Create object
        const mail = { sender: email, firstName: firstName, lastName: lastName, message: message };

        // Prepare Error Toast Keywords
        var invalidWhat = 'Submission'
        var invalidWhy = ''


        // Regex to check for crrect forms
        const isAlphaRegex = new RegExp(/^[a-zA-Z]*$/);
        const isEmailRegex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);

        // If the user didn't fill out the form enough that's an easy check.
        if (email.length < 2 || lastName.length < 2 || firstName.length < 2 || message.length < 2 || !isEmailRegex.test(email) || !isAlphaRegex.test(lastName) || !isAlphaRegex.test(firstName)) {
            if (lastName.length < 2) { invalidWhat = 'Last Name'; invalidWhy = 'Length' };
            if (firstName.length < 2) { invalidWhat = 'First Name'; invalidWhy = 'Length' };
            if (email.length < 2) { invalidWhat = 'Email'; invalidWhy = 'Length' };
            if (message.length < 2) { invalidWhat = 'Message'; invalidWhy = 'Length' };

            if (!isAlphaRegex.test(lastName)) { invalidWhat = 'Last Name'; invalidWhy = 'Values' }
            if (!isAlphaRegex.test(firstName)) { invalidWhat = 'First Name'; invalidWhy = 'Values' }
            if (!isEmailRegex.test(email)) { invalidWhat = 'Email'; invalidWhy = 'Values' }

            return toast.info("Invalid " + invalidWhat + " " + invalidWhy, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
                toastId: Date.now()
            });
        }

        // Attempt the fetch request of the data. return success
        try {
            console.log("JSON Mail: " + JSON.stringify(mail))
            const response = await toast.promise(fetch('https://express-wup1.onrender.com/emailapi', { method: 'POST', headers: { "Content-Type": "application/json" }, body: JSON.stringify(mail) }), {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
                pending: "Sending Mail...",
                success: "Email Sent Successfully",
                error: "Email Encountered Error",
                toastId: Date.now()
            });
            console.log("Response: ", response);

            const json = await response.json();
            console.log("Json: ", json);

            console.log("Success: ", json.data);
            formReset();

            return json.data;
        } catch (error) {
            console.log("Error Found on FETCH")
            return false;
        }
    }

    const formReset = () => {
        setEmail('');
        setFirstName('');
        setLastName('');
        setMessage('');
    }


    const content = (
        <>
            <div className="contact form-container">
                <form className="contact form" onSubmit={formSubmit} onReset={formReset}>
                    <div className="contact formSeg">
                        <div className="contact col">
                            <input autoComplete="off" type="email" className="contact form-control" id="emailInput" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </div>
                    </div>
                    <div className="contact formSeg">
                        <div className="contact col">
                            <input autoComplete="off" type="text" className="contact form-control" id="firstNameInput" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                        </div>
                    </div>
                    <div className="contact formSeg">
                        <div className="contact col">
                            <input autoComplete="off" type="text" className="contact form-control" id="lastNameInput" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                        </div>
                    </div>
                    <div className="contact formSeg">
                        <div className="contact col">
                            <textarea autoComplete="off" type="textarea" className="contact form-control" id="comments" rows="5" cols="40" placeholder="Message..." value={message} onChange={(e) => setMessage(e.target.value)} required></textarea>
                        </div>
                    </div>
                    <div className="row form-buttons">
                        <input type="submit" className="contact btn submit" value="Send Message" />
                        <input type="reset" className="contact btn reset" value="Clear Form" />
                    </div>
                </form>
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={false}
                    draggable={false}
                    pauseOnHover={false}
                    theme="dark"
                />
            </div>
        </>
    )
    return content;
}
export default ContactForm;