import {Routes, Route, BrowserRouter} from 'react-router-dom'

import About from './pages/About.js'
import Splash from './pages/Splash.js'
import Contact from './pages/Contact.js'
import Projects from './pages/Projects.js'
import Navbar from './components/Navbar.js'

function App() {

  return (
    <div className='Splash'>
      <BrowserRouter>
        <Navbar />
        <div className='navLinks'>
          <Routes>
            <Route path='/' element={<Splash />} />
            <Route path='/about' element={<About />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/project' element={<Projects />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
