import ContactForm from '../components/ContactForm';
import choppingWood from '../images/choppingWood.png'

import { useMediaQuery } from 'react-responsive'

const Contact = () => {

    const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' })

    const content = (
        <>
            <meta charSet="utf-8" />
            <meta name="viewport"
                content="width=device-width, initial-scale=1.0, shrink-to-fit=no" />
            <div className='contact-bg'>
                <div className="contact container">
                    <div className="contact cText">
                        <h1 className='contact conTitle'>Post Office</h1>

                        {/* Desktop */}
                        {!isSmallScreen && <div className='contact row'>
                            <div className='contact col'>
                                <ContactForm />
                            </div>
                            <div className='contact col'>
                                <img src={choppingWood} alt='choppnWood' />
                            </div>
                        </div>}


                        {/* Mobile */}
                        {isSmallScreen && <div className='contact col'>
                            <ContactForm />
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
    return content;
}
export default Contact;
