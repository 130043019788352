import { useMediaQuery } from 'react-responsive'
import catPhoto from '../images/cat3.jpg'
import { useState } from 'react'

const Projects = () => {
  const [toggle, setToggle] = useState("");
  const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' })

  const content = (
    <>
      <meta charSet="utf-8" />
      <meta name="viewport"
        content="width=device-width, initial-scale=1.0, shrink-to-fit=no" />
      <div className="project-bg">
        <div className="project container">
          <h1 className="project projTitle">Archives</h1>

          {/* Desktop */}
          {!isSmallScreen && <div className="project row">
            <div className="project col">
              <div className="project aText">
                <br />
                <br />
                <br />
                <br />
                <button className="project nfo btn" onClick={() => setToggle('VIZ')}>Projects Visualized</button>
                <br />
                <br />
                <button className="project nfo btn" onClick={() => setToggle('REPO')}>Github Repositories</button>
              </div>
            </div>
            <div className="project col">
              {toggle == 'VIZ' && <div className="project pPic">
                <h1 className='project'>Projects Visualized</h1>
                <br />
                <a
                  href="https://public.tableau.com/views/RealEstateEDA/MedianPricebyStateMax5Bed5Bath?:language=en-US&publish=yes&:sid=&:display_count=n&:origin=viz_share_link" target='_blank' rel="noreferrer">
                  <button className='project nfo btn'>Real Estate Tableau Dashboard</button>
                </a>
              </div>}

              {toggle == 'REPO' && <div className="project pPic">
                <h1 className='project'>Github Code Repository</h1>
                <br />
                <a href="https://github.com/Logan-0/MachineLearning" target='_blank' rel="noreferrer">
                  <button className='project nfo btn'>Machine Learning</button>
                </a>
                <a href="https://github.com/Logan-0/HackerRank" target='_blank' rel="noreferrer">
                  <button className='project nfo btn'>Hacker Rank</button>
                </a>
                <a href="https://github.com/Logan-0/realEstateScraping" target='_blank' rel="noreferrer">
                  <button className='project nfo btn'>Real Estate Scraping</button>
                </a>
              </div>}
            </div>
          </div >}

          {/* Mobile */}
          {isSmallScreen && <div className="project aText">
            <br />
            <button className="project nfo btn" onClick={() => setToggle('VIZ')}>Projects Visualized</button>
            <br />
            <button className="project nfo btn" onClick={() => setToggle('REPO')}>Github Repositories</button>
          </div>}
          {isSmallScreen && <div className='mobileViewHolder'>

            {toggle == 'VIZ' && <div className="project pPic">
              <h1 className='project'>Projects Visualized</h1>
              <br />
              <a
                href="https://public.tableau.com/views/RealEstateEDA/MedianPricebyStateMax5Bed5Bath?:language=en-US&publish=yes&:sid=&:display_count=n&:origin=viz_share_link" target='_blank' rel="noreferrer">
                <button className='project nfo btn'>Real Estate Tableau Dashboard</button>
              </a>
            </div>}


            {toggle == 'REPO' && <div className="project pPic">
              <h1 className='project'>Github Code Repository</h1>
              <a href="https://github.com/Logan-0/MachineLearning" target='_blank' rel="noreferrer">
                <button className='project nfo btn'>Machine Learning</button>
              </a>
              <a href="https://github.com/Logan-0/HackerRank" target='_blank' rel="noreferrer">
                <button className='project nfo btn'>Hacker Rank</button>
              </a>
              <a href="https://github.com/Logan-0/realEstateScraping" target='_blank' rel="noreferrer">
                <button className='project nfo btn'>Real Estate Scraping</button>
              </a>
            </div>}
          </div>}
        </div >
      </div >
    </>
  );
  return content;
};
export default Projects;
