import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

const Navbar = () => {

    const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' })

    const location = useLocation();

    var isSplashPage = false;

    if (location.pathname === '/') {
        isSplashPage = true;
    }

    const classNameToAdd = isSplashPage ? "nav-splash" : "nav-other"

    const content = (
        <>
            {/* Desktop */}
            {!isSmallScreen && <nav className={"navbar navbar-expand-md justify-content-center " + classNameToAdd}>
                <ul className="navbar-nav">
                    <li className="nav-item home" id='nav-links'>
                        <Link to="/">Home</Link>
                    </li>
                    <li className="nav-item about" id='nav-links'>
                        <Link to="/about">About</Link>
                    </li>
                    <li className="nav-item contact" id='nav-links'>
                        <Link to="/contact">Contact</Link>
                    </li>
                    <li className="nav-item project" id='nav-links'>
                        <Link to="/project">Projects</Link>
                    </li>
                </ul>
            </nav>}
            {isSmallScreen && <div>
                <div class="collapse" id="navbarToggleExternalContent" data-bs-theme="dark">
                    <ul className="navbar-nav d-flex flex-row">
                        <li className="nav-item home" id='nav-links'>
                            <Link to="/">Home</Link>
                        </li>
                        <li className="nav-item about" id='nav-links'>
                            <Link to="/about">About</Link>
                        </li>
                        <li className="nav-item contact" id='nav-links'>
                            <Link to="/contact">Contact</Link>
                        </li>
                        <li className="nav-item project" id='nav-links'>
                            <Link to="/project">Projects</Link>
                        </li>
                    </ul>
                </div>
                <nav className={"navbar navbar-expand-md justify-content-center " + classNameToAdd}>
                    <div class="container-fluid">
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                    </div>
                </nav>
            </div>}


            {/* Mobile */}
            {/* {isSmallScreen && <nav className={"navbar justify-content-center " + classNameToAdd}>
                <button className="navbar-toggler container-fluid" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className='navbar-toggler-icon'></span>
                </button>
            </nav>}
            {isSmallScreen && <div className="collapse" id="navbarToggleExternalContent">
                <ul className="navbar-nav d-flex flex-row">
                    <li className="nav-item home" id='nav-links'>
                        <Link to="/">Home</Link>
                    </li>
                    <li className="nav-item about" id='nav-links'>
                        <Link to="/about">About</Link>
                    </li>
                    <li className="nav-item contact" id='nav-links'>
                        <Link to="/contact">Contact</Link>
                    </li>
                    <li className="nav-item project" id='nav-links'>
                        <Link to="/project">Projects</Link>
                    </li>
                </ul>
            </div>} */}
        </>
    )
    return content
}
export default Navbar