import { useMediaQuery } from 'react-responsive'
import Graphic from '../images/cabin2.png'
import Logo from '../images/Logan0DeveloperLogoPt3.png'

const Splash = () => {

    const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' })

    const content = (
        <>
            <meta charSet="utf-8" />
            <meta name="viewport"
                content="width=device-width, initial-scale=1.0, shrink-to-fit=no" />
            <div className='splash-bg'>
                
                {/* Set this to display for Desktop */}
                {!isSmallScreen && <div className="container">
                    <div className="d-flex align-items-center justify-content-center">
                        <div className='col'>
                            <img
                                className="splash justify-content-center splash-graphic"
                                src={Graphic}
                                alt='Graphic'
                            />
                        </div>
                        <div className='col'>
                            <img
                                className="splash justify-content-center splash-logo"
                                src={Logo}
                                alt='Logo'
                            />
                        </div>
                    </div>
                </div>}

                {/* This arrangement for Mobile */}
                {isSmallScreen && <div className="container">
                    <img
                        className="splash splash-graphic"
                        src={Graphic}
                        alt='Graphic'
                    />
                    <img
                        className="splash splash-logo"
                        src={Logo}
                        alt='Logo'
                    />
                </div>}
            </div>
        </>
    )
    return content;
}
export default Splash;

